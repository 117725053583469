<template>
  <v-sheet elevation="2" class="pa-5" overflow="scroll">
    <v-form v-model="valid" ref="form">
      <v-container fluid>
        <!--Header-->
        <v-row class="mb-5">
          <v-col md="12" sm="12">
            <h2>{{ returnModalType() }} Account</h2>
          </v-col>
        </v-row>
        <!--Form-->
        <v-row>
          <v-col md="6" sm="6">
            <!--Account Type-->
            <v-autocomplete
              v-model="account_type"
              :items="account_types"
              item-text="name"
              return-object
              dense
              outlined
              label="Account Type"
              :rules="typeRules"
              required
            >
            </v-autocomplete>
          </v-col>
          <!--Parent Account-->
          <v-col md="6" sm="6">
            <v-autocomplete
              v-model="parent_account"
              :items="
                chart_of_accounts.filter(
                  (item) =>
                    item.account_sub_type === this.account_type.name &&
                    !item.parent_account
                )
              "
              item-text="account_name"
              clearable
              return-object
              dense
              outlined
              label="Parent Account"
              :disabled="!this.account_type"
              hint="Optional: Add account as a sub-ledger of an existing account"
              persistent-hint
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!--Account Code-->
          <v-col md="6" sm="6">
            <v-text-field
              v-model="account_code"
              label="Code"
              dense
              outlined
              counter="25"
              hint="A unique number to identify this account"
              persistent-hint
              :rules="[codeRules.required, codeRules.counter, codeRules.unique]"
              required
            >
            </v-text-field>
          </v-col>
          <!--Account Name-->
          <v-col md="6" sm="6">
            <v-text-field
              v-model="account_name"
              label="Name"
              dense
              outlined
              counter="200"
              hint="A short label to describe this account"
              persistent-hint
              :rules="[nameRules.required, nameRules.counter, nameRules.unique]"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!--Contra Account-->
          <v-col md="3" sm="3">
            <v-select
              v-model="is_contra"
              :items="[
                { text: 'No', value: false },
                { text: 'Yes', value: true },
              ]"
              label="Contra Account?"
              dense
              outlined
            >
              <!--Info tooltip-->
              <template v-slot:append-outer>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline </v-icon>
                  </template>
                  Contra Accounts are used to offset a balance in another
                  <br />related account. They will appear on the balance sheet
                  <br />and deduct from the totals of their parent accounts.
                </v-tooltip>
              </template>
              <!--End of tooltip-->
            </v-select>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--New-->
              <span v-if="returnModalType() === 'Add New'">
                <v-btn text left class="px-3" @click="passDialogChange($event)"
                  >Cancel</v-btn
                >
                <v-btn
                  right
                  color="#33cc99"
                  class="white--text px-3"
                  :disabled="!valid"
                  @click="saveAccount"
                  :loading="loading === true"
                  >Submit
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </span>
              <!--Edit-->
              <span v-else>
                <v-btn text left class="px-3">Cancel</v-btn>
                <v-btn text class="red--text" @click="deleteAccount"
                  >Delete <v-icon right>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  right
                  color="#33cc99"
                  class="white--text px-3"
                  @click="updateAccount"
                  :loading="loading === true"
                  >Save Changes
                  <v-icon right>mdi-floppy</v-icon>
                </v-btn>
              </span>
            </v-card-actions>
            <!--Delete Alert Modal-->
            <LedgerAccountsDeleteDisableDialog
              :delete_alert_modal="delete_alert_modal"
              :delete_alert_message="delete_alert_message"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { newDateToISO, firebaseTimestamp } from "../../../composables/external";
import { showSnackbar } from "../../../globalActions";
export default {
  name: "NewAccountModal",
  props: [
    "accounts",
    "modalType",
    "value",
    "editAccountDialog",
    "filtered_entries",
  ],
  components: {
    LedgerAccountsDeleteDisableDialog: () =>
      import("../components/LedgerAccountsDeleteDisableDialog"),
  },
  data() {
    return {
      valid: false,
      loading: false,
      account_id: null,
      account_type: null,
      parent_account: null,
      chart_of_accounts: [],
      account_sub_type: null,
      account_code: null,
      account_name: null,
      is_contra: false,
      financial_statement: null,
      delete_alert_modal: false,
      delete_alert_message: [],
      year_month_docs: [],
      account_status: "Active",
      typeRules: [(v) => !!v || "An account type is required"],
      nameRules: {
        required: (v) => !!v || "A name is required",
        counter: (v) => v.length <= 200 || "Name can't exceed 200 characters",
        unique: (v) =>
          this.uniqueTest(v, "account_name") ||
          "This name has already been used",
      },
      codeRules: {
        required: (v) => !!v || "A code is required",
        counter: (v) => v.length <= 25 || "Code can't exceed 10 characters",
        unique: (v) =>
          this.uniqueTest(v, "account_code") ||
          "This code has already been used",
      },
      account_types: [
        { header: "Assets" },
        { name: "Current Asset", group: "Assets", type: "Balance Sheet" },
        { name: "Fixed Asset", group: "Assets", type: "Balance Sheet" },
        { name: "Inventory", group: "Assets", type: "Balance Sheet" },
        { name: "Non-Current Asset", group: "Assets", type: "Balance Sheet" },
        { name: "Prepayment", group: "Assets", type: "Balance Sheet" },
        { divider: true },
        { header: "Liabilities", type: "Balance Sheet" },
        {
          name: "Current Liability",
          group: "Liabilities",
          type: "Balance Sheet",
        },
        {
          name: "Non-Current Liability",
          group: "Liabilities",
          type: "Balance Sheet",
        },
        { divider: true },
        { header: "Equity", type: "Balance Sheet" },
        { name: "Owner's Equity", group: "Equity", type: "Balance Sheet" },
        { divider: true },
        { header: "Cost Of Sales" },
        {
          name: "Cost Of Sales",
          group: "Cost Of Sales",
          type: "Income Statement",
        },
        { divider: true },
        { header: "Revenue" },
        { name: "Sales", group: "Revenue", type: "Income Statement" },
        {
          name: "Operating Revenue",
          group: "Revenue",
          type: "Income Statement",
        },
        { name: "Other Income", group: "Revenue", type: "Income Statement" },
        { divider: true },
        { header: "Expenses" },
        {
          name: "Operating Expense",
          group: "Expenses",
          type: "Income Statement",
        },
        {
          name: "Non-Operating Expense",
          group: "Expenses",
          type: "Income Statement",
        },
      ],
    };
  },
  created() {
    this.getUser();
    this.populateEditData();
    this.getAccounts();
  },
  computed: {
    filterAccounts() {
      return this.chart_of_accounts.filter((item) => {
        item.account_sub_type === "Operating Expense";
      });
    },
  },
  methods: {
    getUser() {
      this.account_created_by_id = firebase.auth().currentUser.uid;
      db.collection("users")
        .where("uid", "==", this.account_created_by_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.account_created_by_name = doc.data().displayName;
          });
        });
    },
    // Populate parent ledger dropdown
    getAccounts() {
      const accountsRef = db
        .collection("chart_of_accounts")
        .orderBy("account_name");
      accountsRef.onSnapshot((querySnapshot) => {
        this.chart_of_accounts = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.chart_of_accounts.push(data);
        });
      });
    },
    // Filter parent ledger dropdown list based on account type

    returnModalType() {
      return this.modalType === "new" ? "Add New" : "Edit";
    },
    passDialogChange() {
      this.$emit("closeDialog", false);
    },
    validate() {
      this.$refs.form.validate();
    },
    uniqueTest(value, property) {
      //    Create an array of just the property
      const propOnlyArray = this.chart_of_accounts.map(
        (item) => item[property]
      );
      //    If New Form --> check that array doesn't include value
      if (this.modalType === "new") {
        return !propOnlyArray.includes(value);
      } else if (
        /*  If Edit Form --> // Remove the original database value from array
          (else you won't be able to pass the unique if certain values
          aren't updated)
      */
        this.modalType === "edit" &&
        (value !== this.account_name || value !== this.account_code)
      ) {
        // 1. get index of matching value
        // 2. splice array at index
        propOnlyArray.splice(propOnlyArray.indexOf(this.value[property]));
        return !propOnlyArray.includes(value);
      }
    },
    saveAccount() {
      const batch = db.batch();
      const accountRef = db.collection("chart_of_accounts").doc();
      if (this.$refs.form.validate()) {
        this.loading = true;

        // 1) Add new account
        batch.set(accountRef, {
          account_id: accountRef.id,
          account_code: this.account_code,
          account_name: this.account_name,
          account_type: this.account_type.group,
          account_sub_type: this.account_type.name,
          parent_account: this.parent_account,
          financial_statement: this.account_type.type,
          account_status: "Active",
          is_contra: this.is_contra,
          account_created_by_id: this.account_created_by_id,
          account_created_by_name: this.account_created_by_name,
          account_date_created: newDateToISO(),
          account_created_timestamp: firebaseTimestamp(),
        });
        //  2) Add sub-ledger account id to parent
        if (this.parent_account) {
          const parentRef = db
            .collection("chart_of_accounts")
            .doc(this.parent_account.account_id);
          batch.update(parentRef, {
            sub_ledgers: firebase.firestore.FieldValue.arrayUnion(
              accountRef.id
            ),
          });
        }
        //  3) Submit to database
        batch
          .commit()
          .then(() => {
            this.loading = false;
            showSnackbar("Account added successfully");
          })
          .then(() => {
            this.$refs.form.reset();
            this.passDialogChange();
          })
          .catch((error) => error);
      }
    },
    // DELETE ACCOUNT
    deleteAccount() {
      //  Can't delete an account if there are journal entries
      if (this.filtered_entries.length > 0) {
        this.delete_alert_modal = true;
        const message =
          "Journal entries have already been added to this ledger account. They must be unreconciled and removed before it can be deleted.";
        this.delete_alert_message.push(message);
        return;
        //  Can't delete an account if there are sub-ledgers
      } else if (this.value.sub_ledgers && this.value.sub_ledgers.length > 0) {
        this.delete_alert_modal = true;
        const message =
          "This ledger account has sub-ledgers. You have to delete all sub-ledgers before you can delete this parent ledger.";
        this.delete_alert_message.push(message);
        return;
      }

      if (confirm("Are you sure you want to delete this account?")) {
        const batch = db.batch();
        const docRef = db
          .collection("chart_of_accounts")
          .doc(this.value.account_id);
        //  1) Remove account id from parent sub-ledger array
        if (this.parent_account) {
          const parentRef = db
            .collection("chart_of_accounts")
            .doc(this.parent_account.account_id);
          batch.update(parentRef, {
            sub_ledgers: firebase.firestore.FieldValue.arrayRemove(
              this.value.account_id
            ),
          });
        }
        //  2) Delete document
        this.loading = true;
        batch.delete(docRef);

        // 3) Commit batch
        batch
          .commit()
          .then(() => showSnackbar("Account deleted successfully"))
          .catch((error) => error);
      }
    },
    populateEditData() {
      if (this.modalType === "new") {
        return;
      } else {
        this.account_id = this.value.account_id;
        this.account_type = {
          name: this.value.account_sub_type,
          group: this.value.account_type,
          type: this.value.financial_statement,
        };
        this.account_sub_type = this.value.account_sub_type;
        this.parent_account = this.value.parent_account;
        this.account_code = this.value.account_code;
        this.account_name = this.value.account_name;
        this.financial_statement = this.value.financial_statement;
        this.account_status = this.value.account_status;
        this.is_contra = this.value.is_contra;
      }
    },
    // UPDATE ACCOUNT
    updateAccount() {
      const batch = db.batch();
      const accountRef = db
        .collection("chart_of_accounts")
        .doc(this.value.account_id);

      //  1: Update document
      if (this.$refs.form.validate()) {
        this.loading = true;
        batch.update(accountRef, {
          account_id: this.account_id,
          account_code: this.account_code,
          account_name: this.account_name,
          account_type: this.account_type.group,
          account_sub_type: this.account_type.name,
          parent_account: this.parent_account,
          financial_statement: this.account_type.type,
          account_status: this.account_status,
          is_contra: this.is_contra,
        });
        // 2: A cloud function will be triggered once the document has been updated
        // It will update parent account details on all related sub-ledgers

        // 3: Remove sub-ledger id from old parent
        if (
          this.parent_account &&
          JSON.stringify(this.value.parent_account).localeCompare(
            JSON.stringify(this.parent_account)
          ) !== 0
        ) {
          //  If there was an old parent account we want to remove
          //  the ledger details from it's sub-ledgers array
          if (this.value.parent_account) {
            const oldParentRef = db
              .collection("chart_of_accounts")
              .doc(this.value.parent_account.account_id);
            batch.update(oldParentRef, {
              sub_ledgers: firebase.firestore.FieldValue.arrayRemove(
                this.value.account_id
              ),
            });
          }
          const newParentRef = db
            .collection("chart_of_accounts")
            .doc(this.parent_account.account_id);
          // 4: add sub-ledger id to new parent
          batch.update(newParentRef, {
            sub_ledgers: firebase.firestore.FieldValue.arrayUnion(
              this.value.account_id
            ),
          });
        }

        // 3: Commit batch
        batch
          .commit()
          .then(() => {
            showSnackbar("Account updated successfully");
            this.$router.go();
          })
          .catch((error) => error);
      }
    },
  },
};
</script>
