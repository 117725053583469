var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-5",attrs:{"elevation":"2","overflow":"scroll"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.returnModalType())+" Account")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.account_types,"item-text":"name","return-object":"","dense":"","outlined":"","label":"Account Type","rules":_vm.typeRules,"required":""},model:{value:(_vm.account_type),callback:function ($$v) {_vm.account_type=$$v},expression:"account_type"}})],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.chart_of_accounts.filter(
                function (item) { return item.account_sub_type === this$1.account_type.name &&
                  !item.parent_account; }
              ),"item-text":"account_name","clearable":"","return-object":"","dense":"","outlined":"","label":"Parent Account","disabled":!this.account_type,"hint":"Optional: Add account as a sub-ledger of an existing account","persistent-hint":""},model:{value:(_vm.parent_account),callback:function ($$v) {_vm.parent_account=$$v},expression:"parent_account"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Code","dense":"","outlined":"","counter":"25","hint":"A unique number to identify this account","persistent-hint":"","rules":[_vm.codeRules.required, _vm.codeRules.counter, _vm.codeRules.unique],"required":""},model:{value:(_vm.account_code),callback:function ($$v) {_vm.account_code=$$v},expression:"account_code"}})],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Name","dense":"","outlined":"","counter":"200","hint":"A short label to describe this account","persistent-hint":"","rules":[_vm.nameRules.required, _vm.nameRules.counter, _vm.nameRules.unique],"required":""},model:{value:(_vm.account_name),callback:function ($$v) {_vm.account_name=$$v},expression:"account_name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('v-select',{attrs:{"items":[
              { text: 'No', value: false },
              { text: 'Yes', value: true } ],"label":"Contra Account?","dense":"","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-information-outline ")])]}}])},[_vm._v(" Contra Accounts are used to offset a balance in another "),_c('br'),_vm._v("related account. They will appear on the balance sheet "),_c('br'),_vm._v("and deduct from the totals of their parent accounts. ")])]},proxy:true}]),model:{value:(_vm.is_contra),callback:function ($$v) {_vm.is_contra=$$v},expression:"is_contra"}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',[_c('v-card-actions',[_c('v-spacer'),(_vm.returnModalType() === 'Add New')?_c('span',[_c('v-btn',{staticClass:"px-3",attrs:{"text":"","left":""},on:{"click":function($event){return _vm.passDialogChange($event)}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"white--text px-3",attrs:{"right":"","color":"#33cc99","disabled":!_vm.valid,"loading":_vm.loading === true},on:{"click":_vm.saveAccount}},[_vm._v("Submit "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1):_c('span',[_c('v-btn',{staticClass:"px-3",attrs:{"text":"","left":""}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"red--text",attrs:{"text":""},on:{"click":_vm.deleteAccount}},[_vm._v("Delete "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"white--text px-3",attrs:{"right":"","color":"#33cc99","loading":_vm.loading === true},on:{"click":_vm.updateAccount}},[_vm._v("Save Changes "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-floppy")])],1)],1)],1),_c('LedgerAccountsDeleteDisableDialog',{attrs:{"delete_alert_modal":_vm.delete_alert_modal,"delete_alert_message":_vm.delete_alert_message}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }